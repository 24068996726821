<template>
  <div id="properties">
    <properties-list></properties-list>
  </div>
</template>

<script>
  import PropertiesList from './components/PropertiesList'

  export default{
    name: 'PropertiesView',
    components: {PropertiesList}
  }
</script>
