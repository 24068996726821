<template>
  <div id="properties-list">
    <b-card>
      <b-row>
        <b-col md="4" sm="12">
          <b-form-group label="Proyectos" label-for="project">
            <v-select
              v-model="filtersForm.project"
              label="name"
              placeholder="Selecciona un Proyecto"
              :options="projects"
              :reduce="projects => projects.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Unidad" label-for="property">
            <v-select
              v-model="property_selected"
              label="name"
              placeholder="Selecciona el Tipo de Unidad"
              :options="property"
              :reduce="property => property.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Unidad adicional" label-for="property">
            <v-select
              v-model="aditional_property_type_selected"
              label="name"
              placeholder="Selecciona una Unidad Adicional"
              :disabled="property_selected != 2"
              :options="aditional_property_type"
              :reduce="aditional_property_type => aditional_property_type.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- <b-col md="4" sm="12" v-else>
        </b-col> -->
        <b-col md="4" sm="12">
          <b-form-group label="Identificador" label-for="identifier">
            <b-form-input id="identifier"
            v-model="filtersForm.identifier"
            name="general"
            :disabled="filtersForm.project === '' || filtersForm.project === null"
            placeholder="Ingresa una palabra clave"/>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Modelo" label-for="model">
            <v-select
              v-model="filtersForm.model"
              :options="modelSelect"
              :disabled="filtersForm.project === '' || filtersForm.project === null || property_selected != 1"
              label="name"
              placeholder="Selecciona modelo">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Orientación" label-for="orientation">
            <v-select
              v-model="filtersForm.orientation"
              :options="orientationSelect"
              :reduce="orientationSelect => orientationSelect.id"
              :disabled="filtersForm.project === '' || filtersForm.project === null || property_selected != 1"
              label="name"
              placeholder="Selecciona orientación">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Torre" label-for="tower">
            <v-select
              v-model="filtersForm.tower"
              :options="towerSelect"
              :disabled="filtersForm.project === '' || filtersForm.project === null || towerSelect.length < 1 || property_selected != 1"
              label="name"
              placeholder="Selecciona torre">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Visible" label-for="visible">
            <v-select v-model="filtersForm.visible" :options="visibleSelect" :reduce="visibleSelect => visibleSelect.id" :disabled="filtersForm.project === '' || filtersForm.project === null" label="name" placeholder="Selecciona visibilidad">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- tables -->
    <b-card v-if="property_selected === 1">
      <b-row>
        <b-col md="6" sm="6">
          <b-row>
            <b-col md="9" sm="9">
              <b-form-group label="" label-for="project">
                <v-select v-model="actionsOptionsGrouped" label="text" placeholder="Acciones grupales" :options="optionsGrouped">
                  <span slot="no-options">
                    No se encontraron opciones
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="3">
              <b-overlay
              :show="actionsOptionsGroupedLoading" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block">
                <b-button ref="button" variant="primary" @click.prevent="changeGroupedItems()" spinner-small spinner-variant="primary">
                  <span class="align-middle">
                    Aplicar
                  </span>
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6" sm="6">
          <div class="d-flex align-items-end justify-content-end">

             
             <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display:none" id="excelUpdatePropertiesInput" type="file" @change="_previewFileExcel"
             :disabled="overlayFormUpload || (project_selected_obj.crm_id != null && project_selected_obj.crm_id != '')">  
              <b-overlay v-if="filtersForm.project != null" :show="overlayFormUpload" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="_onHidden()" >
              <b-button ref="button" variant="primary" :disabled="overlayFormUpload || (project_selected_obj.crm_id != null && project_selected_obj.crm_id != '')"  @click="chooseFiles()" class="marginrigth24">
                <feather-icon icon="UploadIcon" class="mr-50"/>
                <span class="align-middle">
                  Actualizar unidades
                </span>
              </b-button>
            </b-overlay> 

            <b-overlay :show="overlayForm" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="_onHidden()" >
              <b-button ref="button" variant="primary" :disabled="overlayForm" @click.prevent="_exportProperties()" v-if="permissions_visible.download_properties" >
                <feather-icon icon="DownloadIcon" class="mr-50"/>
                <span class="align-middle">
                  Descargar Unidades
                </span>
              </b-button>
            </b-overlay>

           
          </div>
        </b-col>
      </b-row>
      <div class="scroller mb-1">
        <b-table
          hover
          striped
          show-empty
          class="position-relative mb-1 mt-2 has-text-centered"
          :items="properties.data"
          :fields="tableColumns"
          primary-key="id"
          empty-text="Datos no Disponibles">
          
          <template v-slot:head(selected)="data"> 
              <span><b-form-checkbox v-model="allSelected"></b-form-checkbox></span><span></span>
          </template>

          <template #cell(selected)="data">
            <b-form-checkbox v-model="data.item.selected" inline></b-form-checkbox>
          </template>
          <template #cell(orientation)="data">
              {{ checkOrientation(data.item.orientation) }}
          </template>
          <template #cell(available)="data">
            <feather-icon size="16" :icon="data.item.available ? 'CheckIcon' : 'XIcon'" class="align-middle text-body"/>
          </template>

          <template #cell(vulnerable)="data">
            <feather-icon size="16" :icon="data.item.vulnerable == '1' ? 'CheckIcon' : 'XIcon'" class="align-middle text-body"/>
          </template>

          <template #cell(sectores_medios)="data">
            <feather-icon size="16" :icon="data.item.sectores_medios == '1' ? 'CheckIcon' : 'XIcon'" class="align-middle text-body"/>
          </template>

          <template #cell(visible)="data">
            <b-badge pill :variant="data.item.visible ? 'light-success' : 'light-danger'">{{ data.item.visible ? 'Visible' : 'Oculto' }}</b-badge>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content> <feather-icon size="16" icon="MoreVerticalIcon" class="align-middle text-body"/> </template>
              <b-dropdown-item @click.prevent="_handleSelected(data.item)" v-if="permissions_visible.edit_properties">
                <feather-icon icon="ArrowRightCircleIcon" />
                <span class="align-middle ml-50">Detalles</span>
              </b-dropdown-item>

              <b-dropdown-item @click.prevent="_handleAuditoriaSelected(data.item)" v-if="is_admin">
                <feather-icon icon="BookmarkIcon"/>
                <span class="align-middle ml-50">Auditoria</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.complements ? data.item.complements.length > 0 : false" @click.prevent="sidebar = true, propertyData = data.item">
                <feather-icon icon="AlignLeftIcon" />
                <span class="align-middle ml-50">Adicionales</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <b-pagination
        align="right"
        v-model="filtersForm.page"
        hide-goto-end-buttons
        :total-rows="properties.total > 0 ? properties.total : 0"/>
    </b-card>
    <b-card v-else-if="property_selected === 2">
      <div v-if="aditional_property_type_selected === 1 || aditional_property_type_selected === 2">
        <parkinglots-list :filtersForm="filtersForm" v-if="aditional_property_type_selected === 1"></parkinglots-list>
        <warehouses-list :filtersForm="filtersForm" v-if="aditional_property_type_selected === 2"></warehouses-list>
      </div>
      <div v-else class="d-flex align-items-center">
        <strong>Selecciona una unidad principal o adicional para visualizar los datos</strong>
        <b-spinner class="ml-auto" type="grow" variant="primary"></b-spinner>
      </div>
    </b-card>
    <b-card v-else>
      <div class="d-flex align-items-center">
        <strong>Selecciona una unidad principal o adicional para visualizar los datos</strong>
        <b-spinner class="ml-auto" type="grow" variant="primary"></b-spinner>
      </div>
    </b-card>
    <b-sidebar v-if="propertyData != null" :visible="sidebar" no-header right shadow>
        <template #default="{ hide }">
        <div class="p-3">
            <h4 id="sidebar-no-header-title">Unidades adicionales</h4>
            <div class="body-sidebar">
            <div v-for="(complement, index) in propertyData.complements" :key="index">
                <div v-if="propertyData.complements[index].warehouse != null" class="properties">
                    <span>Bodega</span>
                    <div class="details">
                        <feather-icon
                        icon="PackageIcon"
                        class="mr-50"/>
                        <span class="align-middle">#IC-{{propertyData.complements[index].warehouse.id}} Disponible: {{propertyData.complements[index].warehouse.price}} UF</span>
                    </div>
                </div>
                <div v-if="propertyData.complements[index].parkinglot != null" class="properties">
                    <span>Estacionamiento</span>
                    <div class="details">
                        <span class="align-middle">#IC-{{propertyData.complements[index].parkinglot.id}} Disponible: {{propertyData.complements[index].parkinglot.price}} UF</span>
                    </div>
                </div>
            </div>
          </div>
          <b-button class="mt-1" variant="primary" block @click="hide, sidebar = false">Cerrar</b-button>
        </div>
        </template>
    </b-sidebar>
  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from 'vuex'
  import ParkinglotsList from "./ParkinglotsList"
  import WarehousesList from "./WarehousesList"
  import ToastificationContent from "@core/components/toastification/ToastificationContent"
  import { BSpinner } from 'bootstrap-vue'

  export default {
    name: 'PropertiesList',
    components: {
      ParkinglotsList,
      WarehousesList,
      BSpinner
    },
    data () {
      return {
        allSelected:false,
        is_admin:false,
        permissions_visible:{
          edit_property:false,
          create_properties:false,
          edit_properties:false,
          show_properties:false,
          download_properties:false,
        },
        test: false,
        sidebar: false,
        propertyData: null,
        overlayForm: false,
        project: '',
        currentPage: 1,
        totalPage: 0,
        project_selected: '',
        property_selected: 1,
        aditional_property_type_selected: 0,
        filtersForm: {
          project: null,
          page: 1,
          identifier: '',
          model: null,
          orientation: null,
          tower: null,
          visible: null,
        },
        property: [
          {id: 1, name:'Principal'},
          {id: 2, name:'Adicional'}
        ],
        aditional_property_type: [
          {id: 1, name:'Estacionamiento'},
          {id: 2, name:'Bodega'}
        ],
        modelSelect: [],
        towerSelect: [],
        visibleSelect: [
          {id: true, name:'Visible'},
          {id: false, name:'Oculto'}
        ],
        orientationSelect: [
          {id: 'N', name: 'Norte'},
          {id: 'S', name:'Sur'},
          {id: 'E', name:'Oriente'},
          {id: 'W', name:'Poniente'},
          {id: 'NE', name:'Nororiente'},
          {id: 'NW', name:'Norponiente'},
          {id: 'SE', name:'Suroriente'},
          {id: 'SW', name:'Surponiente'},
        ],
        tableColumns: [ 
          //{key: 'id', sortable: false, label: 'ID'},
          {key: 'selected', sortable: false, label: 'Seleccion', thClass: 'h-auto'},
          {key: 'identifier', sortable: true, label: 'Identificador'},
          {key: 'model', sortable: true, label: 'Modelo'},
          {key: 'orientation', sortable: true, label: 'Orientación'},
          {key: 'tower', sortable: true, label: 'Torre'},
          {key: 'price', sortable: true, label: 'Precio'},
          {key: 'bedrooms',sortable: true, label: 'Dormitorios'},
          {key: 'bathrooms',sortable: true, label: 'Baños'},
          {key: 'floor', sortable: true, label: 'Piso'},
          {key: 'built_area', sortable: true, label: 'Área de construcción'},
          {key: 'visible', sortable: true, label: 'Estado'},
          {key: 'vulnerable', sortable: true, label: 'Vulnerable'},
          {key: 'sectores_medios', sortable: true, label: 'Sector Medio'},
          {key: 'available', sortable: false, label: 'Disponibilidad'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ],
        actionsOptionsGrouped:null,
        actionsOptionsGroupedLoading:false,
        optionsGrouped: [
          { value: null, text: 'Acciones para elementos seleccionados' },
          { value: '1', text: 'Visible todas las unidades' },
          { value: '2', text: 'Ocultar todas las unidades' },
          { value: '3', text: 'Habilitar opción vulnerable' },
          { value: '4', text: 'Deshabilitar opción vulnerable' },
          { value: '5', text: 'Habilitar opción sector medio' },
          { value: '6', text: 'Deshabilitar opción sector medio' },
        ],
        excel: [],
        overlayFormUpload:false,
        project_selected_obj:{}

      }
    },
    beforeMount() {
      // this.filtersForm.project = null
      if(this.property_select.property != undefined) {
        this.property_selected = this.property_select.property
        this.aditional_property_type_selected = this.property_select.aditional_property
      }
      if(this.filter_project.project != undefined) {
        this.filtersForm.project = this.filter_project.project
        this.filtersForm.identifier = this.filter_project.identifier
        this.filtersForm.model = this.filter_project.model
        this.filtersForm.orientation = this.filter_project.orientation
        this.filtersForm.tower = this.filter_project.tower
        this.filtersForm.visible = this.filter_project.visible
      }
      this._fetchProperties(this.filtersForm)
      this.test = true
    },
    watch: {
      
      "filtersForm.project": function (val) { 
        this.projects.forEach(element => {
          if(element.id == val){
            this.project_selected_obj = element;
          }
        }); 
      },
      filtersForm: {
        handler(filter){
          this._projectProperty(filter)
          if(this.property_selected === 1){
            if(filter.project != null){
              this._propertiesExtraData(filter.project)
            }else{
              this.filtersForm.model = null,
              this.filtersForm.orientation = null
              this.filtersForm.tower = null,
              this.filtersForm.visible = null,
              this.filtersForm.identifier = ''
              this.towerSelect = []
              this.modelSelect = []
            }
            this._fetchProperties(filter)
          }
          if(this.filtersForm.project === null && this.property_select.property != undefined){
            this.property_selected = null
            this.aditional_property_type_selected = null
          }
        },
        deep: true
      },
      allSelected(){ 
        this.properties.data.forEach(element => {
          element.selected = this.allSelected;
        });
      },
      property_selected(){
          if(this.property_selected === null){
            this.aditional_property_type_selected = null
          }
          this.cleanExtraFilters()
          this.projectSelect({property: this.property_selected, aditional_property: this.aditional_property_type_selected})
          if(this.property_selected === 1 && this.filtersForm.project != null){
              this._propertiesExtraData(this.filtersForm.project)
          }
      },
      aditional_property_type_selected(){
          this.cleanExtraFilters()
          this.projectSelect({property: this.property_selected, aditional_property: this.aditional_property_type_selected})
      }
    },
    computed: {
      ...mapState('properties',['properties', 'parkinglots', 'warehouses', 'property_select', 'filter_project', 'properties_extra_data']),
      ...mapGetters('profile',['projects']),
      excelErrors() {
        // const errors = []
        // if (!this.$v.excel.$dirty) return errors
        // !this.$v.excel.requiredIf && errors.push('Debes ingresar una archivo')
        // return errors
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      this.is_admin = this.$PermissionHelper.getRole().toLowerCase() == 'admin';
    },
    methods: {
      ...mapActions('properties',['propertiesExtraData', 'projectSelect', 'projectProperty', 'fetchWarehouses', 'fetchParkinglots', 'fetchProperties','exportProperties',
      'changeVisibleGroupedProperties','changeVulnerableGroupedProperties','changeSectorMedioGroupedProperties','updatePropertiesExcel'
      ]),
      _propertiesExtraData(project_id){
          this.propertiesExtraData(project_id)
          .then(res => {
            this.towerSelect = res.data.propertiesandmodelsbyproject.towers
            this.modelSelect = res.data.propertiesandmodelsbyproject.models
          })
      },
      _projectProperty(filter){
          this.projectProperty(filter)
      },
      chooseFiles: function() {
          document.getElementById("excelUpdatePropertiesInput").click()
      },
      cleanExtraFilters(){
          if(this.filtersForm.project === null && this.property_select.property != undefined){
              this.filtersForm.model = null,
              this.filtersForm.orientation = null
              this.filtersForm.tower = null,
              this.filtersForm.visible = null,
              this.filtersForm.identifier = ''
              this.towerSelect = []
              this.modelSelect = []
          }
      },
      checkOrientation(orientation){
        switch (orientation) {
          case 'N':
            return 'Norte'
          case 'S':
            return 'Sur'
          case 'W':
            return 'Poniente'
          case 'E':
            return 'Oriente'
          case 'NW':
            return 'Norponiente'
          case 'NE':
            return 'Nororiente'
          case 'SW':
            return 'Surponiente'
          case 'SE':
            return 'Suroriente'
          default:
            return orientation
        }
      },
      async _fetchProperties(filter) {
        await this.fetchProperties(filter)
      },
      async _uploadProperties(){
        
      },
      async _exportProperties(){
        this.overlayForm = true
        var params = {
          project_id:this.filtersForm.project,
          is_main:this.property_selected,
          property_type:this.aditional_property_type,
        };
        await this.exportProperties(params)
        .then(res => {})
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      changeGroupedItems() {
        if(this.actionsOptionsGrouped == null || this.actionsOptionsGroupedLoading){ return false; }
        
        this.actionsOptionsGroupedLoading = true;
        var selected_ids = [];
        if(this.properties.data != undefined){
          this.properties.data.forEach(element => {
            if(element.selected === true){
              selected_ids.push(element.id);
            }
          });
        } 
        if(selected_ids.length == 0){
          this.actionsOptionsGrouped = null;
          this.actionsOptionsGroupedLoading = false;
          return false;
        }
        var params = { propertie_type:"main_property", properties:selected_ids, 
          visible:this.actionsOptionsGrouped.value == "1" ? true : false ,
          vulnerable:this.actionsOptionsGrouped.value == "3" ? true : false ,
          sector_medio:this.actionsOptionsGrouped.value == "5" ? true : false ,
        }; 

        switch (this.actionsOptionsGrouped.value) {
          //visible
          case '1':
          case '2':
              this.changeVisibleGroupedProperties(params)
              .then(res => {
                this.properties.data.forEach(element => {
                  if(element.selected === true){ element.visible = params.visible; element.selected = false; }
                }); 
                this.$toast({component: ToastificationContent, props: { title: 'Cambio realizado con exito', icon: 'ThumbsUpIcon', variant: 'success', },}) 
              })
              .catch(err => {
                this.$toast({ component: ToastificationContent, props: { title: 'Problemas con la accion en grupo', icon: 'AlertCircleIcon', variant: 'danger', }, })
              }).finally(() => {
                this.actionsOptionsGrouped = null;
                this.actionsOptionsGroupedLoading = false;
                this.allSelected = false;
              }) 
          break;
          //vulnerable
          case '3':
          case '4':
            this.changeVulnerableGroupedProperties(params)
              .then(res => {
                this.properties.data.forEach(element => {
                  if(element.selected === true){ element.vulnerable = params.vulnerable; element.selected = false; }
                }); 
                this.$toast({component: ToastificationContent, props: { title: 'Cambio realizado con exito', icon: 'ThumbsUpIcon', variant: 'success', },}) 
              })
              .catch(err => {
                this.$toast({ component: ToastificationContent, props: { title: 'Problemas con la accion en grupo', icon: 'AlertCircleIcon', variant: 'danger', }, })
              }).finally(() => {
                this.actionsOptionsGrouped = null;
                this.actionsOptionsGroupedLoading = false;
                this.allSelected = false;
              }) 
          break;
          //sector medio
          case '5':
          case '6':
            this.changeSectorMedioGroupedProperties(params)
              .then(res => {
                this.properties.data.forEach(element => {
                  if(element.selected === true){ element.sectores_medios = params.sector_medio; element.selected = false; }
                }); 
                this.$toast({component: ToastificationContent, props: { title: 'Cambio realizado con exito', icon: 'ThumbsUpIcon', variant: 'success', },}) 
              })
              .catch(err => {
                this.$toast({ component: ToastificationContent, props: { title: 'Problemas con la accion en grupo', icon: 'AlertCircleIcon', variant: 'danger', }, })
              }).finally(() => {
                this.actionsOptionsGrouped = null;
                this.actionsOptionsGroupedLoading = false;
                this.allSelected = false;
              }) 
          break;
          
        }
         


      },
      _handleSelected(data) {
        this.$router.push({ name: 'properties-details', params: { id: data.id, property: data } })
      },
      _handleAuditoriaSelected(data) { 
        this.$router.push({ name: 'properties-audits', params: { id: data.id, audits: data } })
      },
      _onHidden() {
        this.$refs.button.focus()
      },

      //excel
      _previewFileExcel(event) {
        console.log("_previewFileExcel");
        if(event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
          this.excel = event.target.files[0]
          this.overlayFormUpload=true;
          this._updatePropertiesExcel()
        }else{ 
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Formato de archivo incorrecto',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async _updatePropertiesExcel(){  
        
        await this.updatePropertiesExcel(
          
          {excel:this.excel,project_id:this.filtersForm.project}

        ).then(res => {    

          if(res.data.updatePropertiesByExcel.status){
            this.$toast({component: ToastificationContent, props: { title: 'Cambio realizado con exito', icon: 'ThumbsUpIcon', variant: 'success', },}) 
          }else{
            this.$toast({component: ToastificationContent,props: {title: 'El formato es incorrecto',icon: 'AlertCircleIcon',variant: 'danger',},})
          }

        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ha ocurrido un error al verificar el Excel',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.overlayFormUpload=false;
        })
      },
    },
  }
</script>

<style>
    .scroller {
        width: 100%;
        overflow-x: scroll;
    }
    .marginrigth24{
      margin-right: 14px;
    }
</style>