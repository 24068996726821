<template>
    <div id="parkinglots-list">
      <b-row>
      <b-col md="6" sm="6">
      <b-row>
        <b-col md="9" sm="9">
          <b-form-group label="" label-for="project">
            <v-select
              v-model="actionsOptionsGrouped"
              label="text"
              placeholder="Acciones grupales"
              :options="optionsGrouped"
              >
              >
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>   

        </b-col>
        <b-col md="3" sm="3">
          <b-overlay
          :show="actionsOptionsGroupedLoading"
          opacity="0.5"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block">
            <b-button
              ref="button"
              variant="primary" 
              @click.prevent="changeGroupedItems()"
              spinner-small
              spinner-variant="primary"
              >
              <span class="align-middle">
                Aplicar
              </span>
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>

      </b-col>
      <b-col md="6" sm="6">

        <div class="d-flex align-items-end justify-content-end">
          <b-overlay
            :show="overlayForm"
            opacity="0.5"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
            @hidden="_onHidden()" >
            <b-button
              ref="button"
              variant="primary"
              :disabled="overlayForm"
              @click.prevent="_exportParkinglots()">
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"/>
              <span class="align-middle">
                Descargar Estacionamientos
              </span>
            </b-button>
          </b-overlay>
        </div>
      </b-col>
      </b-row>
      <div class="scroller mb-1">
        <b-table
            hover
            striped
            show-empty
            ref="refQuotationsListTable"
            class="position-relative mb-1 mt-2"
            :items="parkinglots.data"
            :fields="tableColumns"
            primary-key="id"
            empty-text="Datos no Disponibles">

            <template v-slot:head(selected)="data"> 
                <span><b-form-checkbox v-model="allSelected"></b-form-checkbox></span><span></span>
            </template>

            <template #cell(selected)="data">
              <b-form-checkbox v-model="data.item.selected" inline></b-form-checkbox>
            </template>
            <template #cell(tower)="data">
              {{data.item.tower != "" ? data.item.tower : "Sin datos"}}
            </template>
            <template #cell(available)="data">
              <feather-icon size="16"
                :icon="data.item.visible ? 'CheckIcon' : 'XIcon'"
                class="align-middle text-body"/>
            </template>
            <template #cell(visible)="data">
                <b-badge pill :variant="data.item.visible ? 'light-success' : 'light-danger'">
                    {{ data.item.visible ? 'Visible' : 'Oculto' }}
                </b-badge>
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret
                    :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                    <feather-icon size="16"
                        icon="MoreVerticalIcon"
                        class="align-middle text-body"/>
                    </template>
                    <b-dropdown-item @click.prevent="_handleSelected(data.item)">
                        <feather-icon icon="ArrowRightCircleIcon" />
                        <span class="align-middle ml-50">Detalles</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="sidebar = true, parkinglotData = data.item">
                      <feather-icon icon="AlignLeftIcon" />
                      <span class="align-middle ml-50">Adicionales</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click.prevent="_handleAuditoriaSelected(data.item)" v-if="is_admin">
                      <feather-icon icon="BookmarkIcon"/>
                      <span class="align-middle ml-50">Auditoria</span>
                    </b-dropdown-item>

                </b-dropdown>
            </template>
          </b-table>
        </div>
        <b-pagination
        align="right"
        v-model="filtersForm.page"
        hide-goto-end-buttons
        :total-rows="parkinglots.total"/>
        <b-sidebar v-if="parkinglotData != null" :visible="sidebar" no-header right shadow>
          <template #default="{ hide }">
          <div class="p-3">
              <h4 id="sidebar-no-header-title">Unidades adicionales</h4>
              <div class="body-sidebar">
                  <div class="properties">
                      <span>Principal</span>
                      <div class="details">
                      <span class="align-middle">#IC-{{parkinglotData.id}} Disponible: {{parkinglotData.price}} UF</span>
                      </div>
                  </div>
            </div>
            <b-button class="mt-1" variant="primary" block @click="hide, sidebar = false">Cerrar</b-button>
          </div>
          </template>
        </b-sidebar>
    </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'
  import { BSpinner } from 'bootstrap-vue'
  export default {
    name: 'ParkinglotsList',
    components:{
      BSpinner
    },
    props: ['filtersForm'],
    data () {
      return {
        is_admin:false,
        allSelected:false,
        parkinglotData: null,
        sidebar: false,
        overlayForm: false,
        currentPage: 1,
        filters: {},
        tableColumns: [
          {key: 'selected', sortable: false, label: 'Seleccion'},
          {key: 'identifier', sortable: true, label: 'Identificador'},
          {key: 'tower', sortable: true, label: 'Torre'},
          {key: 'price', sortable: true, label: 'Precio'},
          {key: 'floor', sortable: true, label: 'Piso'},
          {key: 'built_area', sortable: true, label: 'Área de construcción'},
          {key: 'visible', sortable: true, label: 'Estado'},
          {key: 'available', sortable: false, label: 'Disponibilidad'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ],
        actionsOptionsGrouped:null,
        actionsOptionsGroupedLoading:false,
        optionsGrouped: [
          { value: null, text: 'Acciones para elementos seleccionados' },
          { value: '1', text: 'Visible todas las unidades' },
          { value: '2', text: 'Ocultar todas las unidades' }, 
        ]
      }
    },
    computed: {
      ...mapState('properties',['parkinglots']),
    },
    beforeMount() {
      this._fetchParkinglots(this.filtersForm);
      this.filters = this.filtersForm;
      this.is_admin = this.$PermissionHelper.getRole().toLowerCase() == 'admin';
    },
    watch:{
      filtersForm: {
        immediate: true,
        handler (val, oldVal) {
          this._fetchParkinglots(this.filtersForm)
        },
        deep: true
      },
      allSelected(){ 
        this.parkinglots.data.forEach(element => {
          element.selected = this.allSelected;
        });
      },
    },
    methods: {
      ...mapActions('properties',['fetchParkinglots','exportWarehousesOrParkingLots','changeVisibleGroupedProperties']),
      async _fetchParkinglots(filter) {
        await this.fetchParkinglots(filter)
        // let towerAux = []
        // this.parkinglots.data.forEach(element => {
        //   towerAux.push(element.tower)
        // })
        // this.towerSelect = [...new Set(towerAux)]
      },
      async _exportParkinglots(){
        this.overlayForm = true
        var params = {
          project_id:this.project_id,
          is_main:0,
          property_type:"parkinglot",
        }
        await this.exportWarehousesOrParkingLots(params)
        .then(res => {})
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      _handleAuditoriaSelected(data) { 
        this.$router.push({ name: 'properties-audits', params: { id: data.id, audits: data } })
      },
      _onHidden(){
        this.$refs.button.focus()
      },
      _handleSelected(data) {
        this.$router.push({ name: 'parkinglots-details', params: { id: data.id, parkinglot: data } })
      },
      changeGroupedItems() {

        if(this.actionsOptionsGrouped == null || this.actionsOptionsGroupedLoading
        ){
          return false;
        }
        this.actionsOptionsGroupedLoading = true;

        var selected_ids = [];
        if(this.parkinglots.data != undefined){
          this.parkinglots.data.forEach(element => {
            if(element.selected === true){
              selected_ids.push(element.id);
            }
          });
        }

        if(selected_ids.length == 0){
          this.actionsOptionsGrouped = null;
          this.actionsOptionsGroupedLoading = false;
          return false;
        }
        var params = {
          propertie_type:"parkinglots",
          properties:selected_ids,
          visible:this.actionsOptionsGrouped.value == "1" ? true : false
        };

          
        this.changeVisibleGroupedProperties(params)
        .then(res => {
          
          //TODO refresh data onscreen from service
          this.parkinglots.data.forEach(element => {
            if(element.selected === true){
              element.visible = params.visible;
              element.selected = false;
            }
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cambio realizado con exito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })

        })
        .catch(err => {
            //console.log(err);
            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas con la accion en grupo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.actionsOptionsGrouped = null;
          this.actionsOptionsGroupedLoading = false;
        })
      },
    },
  }
</script>

<style>
    .scroller {
        width: 100%;
        overflow-x: scroll;
    }
</style>